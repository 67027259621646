<template>
  <v-main>
    <v-container id="app-container" class="pa-0">
      <page-app-bar />
      <router-view />
      <page-sign-up />
    </v-container>
    <page-footer />
  </v-main>
</template>

<script>
  // Utilities
  import { get } from 'vuex-pathify'

  export default {
    name: 'PageView',

    components: {
      PageAppBar: () => import('./AppBar'),
      PageSignUp: () => import('./SignUp'),
      PageFooter: () => import('./Footer'),
    },

    data: () => ({
      srcs: {
        '/client/:id/': 'client.jpg',
        '/lock/': 'lock.jpg',
        '/login/': 'login.jpg',
        '/pricing/': 'pricing.jpg',
        '/register/': 'register.jpg',
      },
    }),

    computed: {
      path: get('route/path'),
      id: get('route/params.id'),
      src () {
        let p = this.path
        if (this.id) p = this.path.replace(this.id, ':id')
        return this.srcs[p] || 'clint-mckoy.jpg'
      },
    },
  }
</script>
<style>
 #app-container {
   max-width: 1200px;
   padding: 0 1em;
 }
</style>

